<template>
  <div class="bg">
    <el-container>
      <el-header style="text-align: left; margin-top: 15px">
        <el-row type="flex" align="middle">
          <el-col :span="2">
            <el-upload
              class="upload-demo"
              ref="upload"
              action=""
              :http-request="(value) => handleFileChange(value)"
            >
              <el-button type="primary" icon="el-icon-upload"
                >上传pdf</el-button
              >
            </el-upload>
          </el-col>
          <!-- <el-col :span="2"><uploadfile :type="'006'"></uploadfile></el-col> -->
          <!-- <el-col :span="2" style="margin-left: 15px"
            ><el-button type="primary" icon="el-icon-message"
              >通讯录</el-button
            ></el-col
          > -->
          <el-col :span="2"
            ><el-progress
              v-show="flag == true"
              :percentage="percent"
              :stroke-width="3"
            ></el-progress>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="index" label="序号" width="150">
          </el-table-column>
          <el-table-column prop="name" label="文件名称"> </el-table-column>
          <el-table-column prop="size" label="文件大小"> </el-table-column>
          <el-table-column prop="createTime" label="上传日期">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="sendOpen(scope.row)"
                >发送</el-button
              >
              <el-button
                size="mini"
                type="primary"
                plain
                @click="payOrder(scope.row)"
                >生成二维码</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="resourceDel(scope.row)"
                >删除</el-button
              >
              <el-button v-if="scope.row.sendStatus=='00'" size="mini" type="primary" :loading="true">正在发送</el-button>
              <el-button
                size="mini"
                type="success"
                v-if="scope.row.sendStatus != null && scope.row.sendStatus !='00'"
                @click="listSendMessage(scope.row.id)"
                >发送结果</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页条1 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="req.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          style="text-align: right; margin-top: 1vw"
          :total="resourceTotal"
        ></el-pagination>
      </el-main>
    </el-container>

    <!-- 生成二维码 start -->
    <el-dialog title="生成二维码" :visible.sync="qrDialogVisible" width="30%">
      <el-row>
        <el-col align="middle"
          ><!-- 放置二维码的容器,需要给一个ref -->
          <div id="qrcode" ref="qrcode"></div
        ></el-col>
      </el-row>
      <el-row style="margin-top: 30px">
        <el-col>
          <el-button
            type="info"
            plain
            size="mini"
            style="width: 100px"
            @click="qrDialogVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="qrDownload"
            size="mini"
            style="width: 100px"
            >下载</el-button
          ></el-col
        >
      </el-row>
    </el-dialog>
    <!-- 生成二维码 end -->

    <!--新增邮箱 start -->
    <el-dialog
      title="新增邮箱"
      :visible.sync="newEmailDialogVisible"
      width="30%"
    >
      <el-form ref="newEmailForm" :model="newEmailForm" :rules="newEmailRules">
        <el-form-item label="姓名" prop="name">
          <el-col :span="10">
            <el-input v-model="newEmailForm.name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-col :span="10">
            <el-input v-model="newEmailForm.email"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <el-row style="margin-top: 30px">
        <el-col>
          <el-button
            type="info"
            plain
            size="mini"
            style="width: 100px"
            @click="newEmailDialogVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="width: 100px"
            @click="addEmail"
            >保存</el-button
          ></el-col
        >
      </el-row>
    </el-dialog>
    <!-- 新增邮箱 end -->

    <!-- 发送邮件 start -->
    <el-dialog title="发送邮件" :visible.sync="sendDialogVisible" width="50%">
      <el-row>
        <el-col>
          <el-row style="margin: 15px 0">
            <el-col align="left">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </el-col>
          </el-row>
          <el-row style="max-height: 500px; overflow-y: auto"
            ><el-col align="left">
              <el-checkbox-group
                v-model="checkedCities"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox
                  v-for="city in cities"
                  :label="city.email"
                  :key="city.email"
                  style="display: block; margin: 10px 0"
                >
                  <i class="el-icon-s-custom" style="margin-right: 10px"></i
                  >{{ city.name }}&nbsp;&nbsp;{{ city.email }}&nbsp;&nbsp;
                  <el-popconfirm
                    @confirm="delEmail(city.email)"
                    title="确定删除吗？"
                  >
                    <i
                      class="el-icon-delete danger"
                      slot="reference"
                      @click.stop.prevent
                    ></i>
                  </el-popconfirm>
                </el-checkbox>
              </el-checkbox-group> </el-col
          ></el-row>
        </el-col>
      </el-row>
      <el-row style="margin-top: 30px">
        <el-col>
          <el-button
            type="info"
            plain
            size="mini"
            style="width: 100px"
            @click="sendDialogVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="sendEmail"
            size="mini"
            style="width: 100px"
            >发送</el-button
          >
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="openNewEmailDialog"
            >新增</el-button
          ></el-col
        >
      </el-row>
    </el-dialog>
    <!-- 发送邮件 end -->

    <!-- 发送结果 start -->
    <el-dialog title="发送结果" :visible.sync="sendMessageDialogVisible" width="50%">
      <el-row style="max-height: 500px; overflow-y: auto">
        <el-col>
          <el-table :data="sendMessageTableData" style="width: 100%">
            <el-table-column type="index" label="序号" width="150">
            </el-table-column>
            <el-table-column prop="receiveName" label="收件人"> </el-table-column>
            <el-table-column prop="receiveAddress" label="收件人邮箱"> </el-table-column>
            <el-table-column prop="sendStatus" label="发送状态"> </el-table-column>
            <el-table-column prop="createTime" label="发送日期">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row style="margin-top: 30px">
        <el-col>
          <el-button
            type="info"
            plain
            size="mini"
            style="width: 100px"
            @click="sendMessageDialogVisible = false"
            >关闭</el-button
          >
         </el-col>
      </el-row>
    </el-dialog>
    <!-- 发送结果 end -->
  </div>
</template>

<script>
// import uploadfile from "@/components/uploadFiles.vue";
import QRCode from "qrcodejs2";
import { BASE_CONFIG } from "@/config/config";

const cityOptions = [
  { name: "张三", email: "zhangsan@163.com" },
  { name: "李四", email: "lisi@163.com" },
  { name: "张三1", email: "zhangsan1@163.com" },
  { name: "张三2", email: "zhangsan2@163.com" },
  { name: "张三3", email: "zhangsan3@163.com" },
  { name: "张三4", email: "zhangsan4@163.com" },
];

export default {
  components: {
    // uploadfile,
  },
  mounted() {
    this.getResourceList();
  },
  data() {
    return {
      qrDialogVisible: false,
      sendDialogVisible: false,
      sendMessageDialogVisible: false,
      newEmailDialogVisible: false,
      tableData: [],
      sendMessageTableData:[],
      // 请求参数1
      resourceTotal: 0, //总的条数
      req: {
        problemType: "",
        problemName: "",
        current: 1,
        size: 10,
        orders: [
          {
            column: "a.create_time",
            asc: false, // true升序 false降序
          },
        ],
      },

      //多选框
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: false,

      //新增邮箱
      newEmailForm: {
        name: "",
        email: "",
      },

      newEmailRules: {
        name: [{ required: true, message: "请输入用户姓名", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
      },

      //要发送的pdfurl
      sendUrl: "",
      //要发送的pdf名字
      sendName: "",

      //进度条
      percent: 0,
      flag: false,
    };
  },
  filters:{
  },
  methods: {
    // 分页条
    handleSizeChange(limit) {
      this.req.size = limit;
      this.req.current = 1;
      this.getResourceList();
    },
    handleCurrentChange(page) {
      this.req.current = page;
      this.getResourceList();
    },
    //删除pdf
    resourceDel(row) {
      var token = window.localStorage.getItem("myToken");
      let _self = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(BASE_CONFIG.ROOT_URL + "resource/delResource", {
            resourceId: row.id,
          },{
            headers:{
              token: token
            }
          })
          .then((response) => {
            if (response && response.data && response.data.code == 500) {
              this.$alert(response.data.message, "", {
                confirmButtonText: "关闭",
              });
              return;
            }

            this.$message({
              showClose: true,
              message: "删除成功",
              duration: 1000,
              type: "success",
            });

            _self.getResourceList();
          });
      });
    },
    // 展示二维码
    payOrder(row) {
      var token = window.localStorage.getItem("myToken");
      let _self = this;
      //获取连接
      this.$axios
        .post(
          BASE_CONFIG.ROOT_URL + "resource/getDownloadUrl",
          this.$qs.stringify({
            path: row.url,
          }),{
            headers:{
              token: token
            }
          }
        )
        .then(function (response) {
          console.log(response.data.data);
          //清除已经生成的二维码
          if (_self.$refs.qrcode) {
            _self.$refs.qrcode.innerHTML = "";
          }

          _self.qrDialogVisible = true;
          // 二维码内容,一般是由后台返回的跳转链接
          _self.qrcode = response.data.data;
          // 使用$nextTick确保数据渲染
          _self.$nextTick(() => {
            _self.crateQrcode();
          });
        });
    },
    // 生成二维码
    crateQrcode() {
      this.qr = new QRCode("qrcode", {
        width: 150,
        height: 150, // 高度
        text: this.qrcode, // 二维码内容
        // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'
        // foreground: '#ff0'
      });
      // console.log(this.qrcode)
    },
    qrDownload() {
      let myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = "二维码";
      a.click();
    },

    //全选
    handleCheckAllChange(val) {
      if (val) {
        for (var i = 0; i < this.cities.length; i++) {
          this.checkedCities.push(this.cities[i].email);
        }
      } else {
        this.checkedCities = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },

    delEmail(email) {
      var token = window.localStorage.getItem("myToken");
      this.$axios
        .post(BASE_CONFIG.ROOT_URL + "index/delEmail", {
          email: email,
        },
        {
          headers:{
            token: token
          }
        })
        .then((response) => {
          if (response && response.data && response.data.code == 500) {
            this.$alert(response.data.message, "", {
              confirmButtonText: "关闭",
            });
            return;
          }

          this.$message({
            showClose: true,
            message: "删除成功",
            duration: 1000,
            type: "success",
          });

          this.sendOpen();
        });
    },
    handleFileChange(value) {
      var token = window.localStorage.getItem("myToken");
      var _self = this;
      let file = value.file;
      let fsize = 100; //最大100兆
      if (file.size > 1024 * 1024 * fsize) {
        // 校验文件大小
        this.$message.error("文件大小不能超过" + fsize + "M");
        return;
      } else if (
        !/\.(txt|xls|xlsm|xlsb|xlsx|docx|doc|dotx|ppt|pptx|jpg|jpeg|png|gif|bmp|GIF|JPG|PNG|pdf)$/.test(
          file.name
        )
      ) {
        // 校验文件格式
        this.$message.error(
          "不支持" + file.name.split(".")[1] + "格式文件，请重新上传！"
        );
        return;
      } else {
        if (file.name) {
          //记录大小
          let tempSize = "";
          if (file.size > 1024) {
            if (file.size / 1024 > 1024) {
              tempSize =
                Math.floor((file.size / 1024 / 1024) * 100) / 100 + "M";
            } else {
              tempSize = Math.floor((file.size / 1024) * 100) / 100 + "KB";
            }
          } else {
            tempSize = file.size + "B";
          }

          this.$axios
            .post(
              BASE_CONFIG.ROOT_URL + "resource/uploadFront",
              this.$qs.stringify({
                calculateSignature: "1",
                fileName: file.name,
                size: tempSize,
              }),
              {
                headers:{
                  token: token
                }
              }
            )
            .then(function (response) {
              let signature = response.data.data;
              let resourceId = signature.id;

              //上传到oss
              let param = new FormData(); //创建form对象

              param.append("key", signature.path);
              param.append("policy", signature.policy);
              param.append("OSSAccessKeyId", signature.accessid);
              param.append("success_action_status", "200");
              param.append("signature", signature.signature);
              param.append(
                "file",
                new Blob([file], {
                  type: file.type ? file.type : "jpg",
                }),
                file.name
              );

              _self.flag = true;
              const onUploadProgress = (progressEvent) => {
                let percent =
                  ((progressEvent.loaded / progressEvent.total) * 100).toFixed(
                    0
                  ) || 0;
                _self.percent = parseFloat(percent);
                if (_self.percent == 100) {
                  _self.flag = false;
                  _self.percent = 0;
                }
              };

              let config = {
                headers: {
                  "Content-type": "multipart/form-data",
                },
                onUploadProgress, // 上传进度条
              };

              _self.$axios
                .post(signature.host, param, config)
                .then(function (response) {
                  if (response.status === 200) {
                    console.log("上传成功");
                    //更新文件状态
                    _self.$axios
                      .post(
                        BASE_CONFIG.ROOT_URL + "resource/updateSuccess",
                        _self.$qs.stringify({
                          resourceId: resourceId,
                        }),
                        {
                          headers:{
                            token: token
                          }
                        }
                      )
                      .then(function (response) {
                        console.log(response);
                        _self.$message({
                          message: "上传成功",
                          duration: 1000,
                          type: "success",
                        });

                        //刷新页面
                        _self.req.current = 1;
                        _self.getResourceList();
                        _self.$refs.upload.clearFiles();
                      });
                  } else {
                    console.log("上传失败");
                  }
                });
            });
        }
      }
    },

    getResourceList() {
      var token = window.localStorage.getItem("myToken");
      this.$axios
        .post(BASE_CONFIG.ROOT_URL + "resource/list", {
          current: this.req.current,
          size: this.req.size,
          auth:"yhpdf"
        },{
          headers:{
            token: token
          }
        })
        .then((response) => {
          if(response.data.code == 201){
            //请先登录
            this.$message.error(response.data.message);
            this.$router.push("/login");
            return;
          }
          this.tableData = response.data.data.records;
          this.resourceTotal = parseInt(response.data.data.total);
        });
    },

    sendEmail() {
      var token = window.localStorage.getItem("myToken");
      if (this.checkedCities.length == 0) {
        this.$alert("请先选择要发送的邮箱", "", {
          confirmButtonText: "确定",
        });
        return;
      }
      let param = new FormData(); //创建form对象
      let emailInfo = "{email:'"+this.checkedCities.toString() +"'}";
      param.append("name", this.sendName);
      param.append("url", this.sendUrl);
      param.append("emailInfo", emailInfo);

      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

      this.$axios
        .post(
          BASE_CONFIG.ROOT_URL + "index/sendEmail",
          param,
          {
            headers:{
              token: token
            }
          }
        )
        .then((response) => {
          if (response && response.data && response.data.code == 500) {
            this.$alert(response.data.message, "", {
              confirmButtonText: "关闭",
            });
            return;
          }

          this.$message({
            showClose: true,
            message: "发送成功",
            type: "success",
          });

          this.sendDialogVisible = false;

          loading.close();
          this.getResourceList();
        }).catch(function (error) { // 请求失败处理
        console.log(error);
        loading.close();
      });
    },
    listSendMessage(resourceId){
      var token = window.localStorage.getItem("myToken");
      let param = new FormData(); //创建form对象
      param.append("resourceId", resourceId);
      this.$axios
        .post(BASE_CONFIG.ROOT_URL + "index/listSendMessage",param,
        {
          headers:{
            token: token
          }
        })
        .then((response) => {
          this.sendMessageDialogVisible = true;
          this.sendMessageTableData = response.data.data;
          console.log(this.sendMessageTableData);
        });
    },
    sendOpen(row) {
      var token = window.localStorage.getItem("myToken");
      if (row) {
        this.checkedCities = []; //清空之前选中的复选框
        this.sendUrl = row.url;
        this.sendName = row.name;
      }

      let _self = this;
      this.sendDialogVisible = true;
      this.$axios
        .post(BASE_CONFIG.ROOT_URL + "index/listEmail",null,
        {
          headers:{
            token: token
          }
        })
        .then((response) => {
          _self.cities = response.data.data;
          
        });
    },

    //打开新增邮箱的页面
    openNewEmailDialog() {
      this.newEmailForm = {}; //清空之前的界面数据
      this.newEmailDialogVisible = true;
    },

    addEmail() {
      var token = window.localStorage.getItem("myToken");
      this.$axios
        .post(BASE_CONFIG.ROOT_URL + "index/addEmail", this.newEmailForm,
        {
          headers:{
            token: token
          }
        })
        .then((response) => {
          if (response && response.data && response.data.code == 500) {
            this.$alert(response.data.message, "", {
              confirmButtonText: "关闭",
            });
            return;
          }

          this.$message({
            showClose: true,
            message: "成功",
            type: "success",
          });
          this.newEmailDialogVisible = false;
          this.sendOpen();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common";
</style>